import "./styles.scss";

import React, { useState } from "react";
import ModalVideo from "../ModalVideo";
import ModalSpeech from "../../page_components/about/ModalSpeech";

const RightSideTextCustom = ({ content, frameColor }) => {

  const {aboutHeading, aboutImg, aboutIntro, aboutMain, aboutSpeech, aboutSpeechText, aboutText} = content;

  const [isOpen, setIsOpen] = useState(false);
  const [micOpen, setMicOpen] = useState(false);

  return (
    <div className="right-side-text container-fluid">
          {isOpen && <ModalVideo video_url={aboutIntro} setIsOpen={setIsOpen} />}
          {micOpen && <ModalSpeech speech_url={aboutSpeech} setMicOpen={setMicOpen} />}
          <div className="row">
            <div className="col-md-6 order-1 right-side-text__col-left">
              <div className="right-side-text__img-wrapper">
                <img
                  className="homepage-grid__image"
                  src={aboutImg.sourceUrl}
                  alt={aboutImg.altText}
                />
                <div className="image-hover" onClick={() => setIsOpen(true)}>
                  <div className="image-hover__icon">
                    <img
                      className="homepage-grid__image img-fluid"
                      src={require("assets/svg/play-filled.svg").default}
                      alt=""
                    />
                  </div>
                  <div className="image-hover__time">Maciej Uchmański - Intro (1 min)</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-2 right-side-text__col-right">
              <p className="right-side-text__info">{aboutMain}</p>
              <h2 className="right-side-text__title">{aboutHeading}</h2>
              <div className="right-side-text__text" dangerouslySetInnerHTML={{ __html: aboutText }} />
              <div className="right-side-text__col-right__mic" onClick={() => setMicOpen(true)}>
                <img src={require("assets/images/mic.png").default} alt="" /> 
                <span>{aboutSpeechText}</span> 
              </div>
            </div>
          </div>
        </div>
  );
};

export default RightSideTextCustom;