import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

const RightSideText = ({ content }) => {

  const {aboutListBtn, aboutListHeading, aboutListImg, aboutListLink, aboutListMain, aboutListText} = content;

    return (
        <div className="right-side-text container-fluid">
          <div className="row">
            <div className="col-md-6 order-1 right-side-text__col-left">
              <div className="right-side-text__img-wrapper">
                <img
                  className="homepage-grid__image"
                  src={aboutListImg.sourceUrl}
                  alt={aboutListImg.altText}
                />
              </div>
            </div>
            <div className="col-md-6 order-2 right-side-text__col-right">
              <p className="right-side-text__info">{aboutListMain}</p>
              <h2 className="right-side-text__title">{aboutListHeading}</h2>
              <div className="right-side-text__text" dangerouslySetInnerHTML={{ __html: aboutListText }} />
              <Link className="btn-main" to={aboutListLink}>{aboutListBtn}</Link>
            </div>
          </div>
        </div>
  );
};

export default RightSideText;