import "./styles.scss";

import React from "react";
import Vimeo from '@u-wave/react-vimeo';

const ModalSpeech = ({ speech_url, setMicOpen }) => {

    return (
    <div className="modal-speech" onClick={() => setMicOpen(false)}>
      <div className="modal-speech__close-btn" onClick={() => setMicOpen(false)}>
        <span></span>
        <span></span>
      </div>
      <div className="container">
        <div className="offset-lg-2 col-lg-8">
          <div className="modal-speech__wrapper">
            <Vimeo
              video={speech_url}
              muted={false}
              autoplay={true}
              showPortrait={false}
              showTitle={false}
              color="#e92539"
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSpeech;