import "styles/pages/about.scss";

import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout"
import LeftSideText from "components/LeftSideText";
import RightSideText from "components/RightSideText";
import RightSideTextCustom from "components/RightSideTextCustom";
import ImagesBar from "components/ImagesBar";
import Counter from "components/Counter";

const About = ({ data }) => {

  const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};

  const seo = data.pageAbout?.nodes[0]?.seo;
  const about_page_data = data.pageAbout?.nodes[0]?.pageAbout;

  return (
      <Layout seo={seo} header_setup={header_setup}>
        <section className="about">
          <div className="site-container">
            <div className="section-title">O mnie</div>
            <div className="about__container">
              <div className="about__row__padding">
                <RightSideTextCustom content={about_page_data} frameColor='#DCE0E5' />
              </div>
              {about_page_data?.aboutSections.map((section, index) => {
                
                if (index % 2 === 0) {
                  return (
                    <div key={index} className="about__row__padding">
                      <LeftSideText content={section} />
                    </div>
                    )
                  } else if (index % 2 === 1) {
                    return (
                      <div key={index} className="about__row__padding">
                        <RightSideText content={section} />
                      </div>
                      )
                    }
                  })}
            </div>
          </div>
        </section>

        <Counter backgroundColor="#F1E9D7" fontColor="#1B1717" counterTitle={about_page_data.counterTitle} counterData={about_page_data.counterInfo} />
        <ImagesBar images={about_page_data.aboutGallery} />
      </Layout>
  );
};

export const query = graphql`
  {
    pageAbout: allWpPage(filter: {databaseId: {eq: 63}}) {
      nodes {
        seo {
          title
          metaDesc
        }
        pageAbout {
          counterTitle
          counterInfo {
            counterNumber
            counterSubject
          }
          aboutHeading
          aboutIntro
          aboutMain
          aboutSpeech
          aboutSpeechText
          aboutText
          aboutTitle
          aboutImg {
            altText
            sourceUrl
          }
          aboutSections {
            aboutListBtn
            aboutListHeading
            aboutListLink
            aboutListMain
            aboutListText
            aboutListImg {
              altText
              sourceUrl
            }
          }
          aboutGallery {
            galleryImg {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`

export default About;